<template>
<div>
    <!-- header new -->
    <LandingHeader />

    <!-- hero section -->
    <OtherHero heading="Privacy Policy" />

    <div class="other-container">
        <div class="content-box">
            <p>
                Protecting your private information is our most important priority.
                This Statement of Privacy applies to<a href="/">
                    https://www.syncgrades.com</a><strong>, Sync Grades,</strong> and <strong>Lilo Consulting</strong>,
                <strong>LLC</strong>, which governs data collection and it’s usage.
                For the purposes of this Privacy Policy, unless otherwise noted, all
                references to <strong>Lilo Consulting, LLC</strong> include
                <strong><a
                    href="/"
                    rel="nofollow"
                >http://syncgrades-webfront.s3.amazonaws.com/index.html</a> </strong>and <strong>Sync Grades</strong>.
            </p>
            <p>
                **The <strong>Sync Grades</strong> website and mobile application is a
                secure and protected Attendance &#8211; Grading system created by
                educators for educators; which strictly adheres to all of the security
                provisions stipulated within the
                <strong>Family Educational Rights and Privacy Act (FERPA) (20 U.S.C. §
                    1232g; 34 CFR Part 99)</strong>
                and the <strong>NYSED</strong> &#8211;
                <strong>Personally Identifiable Information Part 121 Terms</strong>
                (AMENDMENT TO THE REGULATIONS OF THE COMMISSIONER OF EDUCATION
                Pursuant to Education Law sections 2-d, 101, 207 and 305, a new Part
                121).
            </p>
            <p>
                By using the
                <strong>Lilo Consulting, LLC., Sync Grades</strong>
                website, you consent to the data practices described in this
                statement.
            </p>
            <p>
                <strong>Collection of your Personal Information</strong>
            </p>
            <p>
                We do not collect any personal information about you unless you
                voluntarily provide it to us.
            </p>
            <p>
                <strong>Sharing Information with Third Parties</strong>
            </p>
            <p>
                **Sync Grades does not <strong>sell</strong>, <strong>rent</strong> or
                <strong>lease</strong> its customer lists to<strong>
                    third parties in any shape, way, or form</strong>.
            </p>
            <p>
                <strong>Sync Grades</strong> will only share information within your
                secure school community as defined below:
            </p>
            <ol>
                <li>
                    <strong>Teachers</strong> will only be allowed to view confidential
                    data on their students assigned by the school administration
                </li>
                <li>
                    <strong>School Administrators</strong> will be able to view
                    schoolwide data on all students within their school
                </li>
                <li>
                    <strong>Parents</strong> will only be able to view data on their
                    daughter(s) or son(s)
                </li>
                <li>
                    <strong>Students</strong> will only be able to view their data
                </li>
                <li>
                    <strong>**NO Data, general or confidential information on students,
                        parents, or staff is shared beyond the closed school
                        community</strong>
                </li>
                <li>
                    <strong>The only exceptions to this rule is if the principal
                        independently shares or formally requests in writing that
                        information be shared with the district, superintendent, NYC
                        Department of Education, or the NYSED</strong>
                </li>
                <li>
                    <strong>Under no circumstance will Sync Grades provide information to a
                        third-party
                    </strong>
                </li>
            </ol>
            <p>
                <strong>Sync Grades</strong> provides teachers with the capacity to
                transmit <strong>report card grades</strong> directly back to
                <strong>Stars Classroom, </strong>via an Excel file. This is a feature
                integrated into <strong>Sync Grades</strong> and is only accessible to
                <strong>teachers</strong>.
            </p>
            <p>
                Also, during the
                <strong>COVID-19 Remote Learning Period</strong>,
                <strong>Sync Grades</strong> allows school administrators to automate
                the attendance data collection process, also known as
                “<strong>Interactions.</strong>” <strong>Sync Grades</strong> allows
                schools to generate the required <strong>Excel file</strong>, needed
                to send to <strong>Stars Classroom</strong>.
            </p>
            <p>
                <strong>Automatically Collected Information</strong>
            </p>
            <p>
                Information about your computer hardware and software may be
                automatically collected by
                <strong>Sync Grades</strong>. This information can include: your IP
                address, browser type, domain names, access times and referring
                website addresses. This information is used for the operation,
                maintenance and quality of service; and to provide general statistics
                regarding use of the <strong>Sync Grades</strong> website.
            </p>
            <p><strong>Use of Cookies</strong></p>
            <p>
                The <strong>Sync Grades</strong> website may use
                &#8220;<strong>cookies</strong>&#8221; to help you personalize your
                online experience. A cookie is a text file that is placed on your hard
                disk by a web page server. Cookies cannot be used to run programs or
                deliver viruses to your computer. Cookies are uniquely assigned to
                you, and can only be read by a web server in the domain that issued
                the cookie to you.
            </p>
            <p>
                One of the primary purposes of cookies is to provide a convenience
                feature to save you time. The purpose of a cookie is to tell the Web
                server that you have returned to a specific page. For example, if you
                personalize
                <strong>Sync Grades</strong> pages, or register with
                <strong>Sync Grades</strong> site or services, a cookie helps
                <strong>Sync Grades</strong> to recall your specific information on
                subsequent visits. This simplifies the process of recording your
                personal information. When you return to the same
                <strong>Sync Grades</strong> website, the information you previously
                provided can be retrieved, so you can easily use the
                <strong>Sync Grades</strong> features that you customized.
            </p>
            <p>
                You have the ability to accept or decline cookies. Most Web browsers
                automatically accept cookies, but you can usually modify your browser
                setting to decline cookies if you prefer. If you choose to
                <strong>decline cookies</strong>, you may not be able to fully
                experience the interactive features of the
                <strong>Sync Grades</strong> services or websites you visit.
            </p>
            <p>
                <strong>Security of your Personal Information</strong>
            </p>
            <p>
                <strong>Sync Grades</strong> secures your personal information from
                unauthorized access, use, or disclosure.
                <strong>Sync Grades</strong> uses the following methods for this
                purpose:
            </p>
            <p>&#8211; <strong>SSL Protocol</strong></p>
            <p>
                When personal information (such as the transfer of report card grades
                to the
                <strong>STARS Classroom</strong>) is transmitted to other websites, it
                is protected through the use of <strong>encryption</strong>, such as
                the<strong> Secure Sockets Layer (SSL) protocol</strong>.
            </p>
            <p>
                <strong>**Sync Grades only transmits data back to Stars Classroom. There
                    are no third-party websites that Sync Grades transmits data
                    to.</strong>
            </p>
            <p><strong>Children Under Thirteen</strong></p>
            <p>
                If you are under the age of thirteen, you must ask your parent or
                guardian for permission to use this website. If you are a parent and
                you have questions regarding our data collection practices, please
                contact us using the information provided at the end of this
                <strong>Statement of Privacy</strong>.
            </p>
            <p>
                <strong>Connecting and Disconnecting your Sync Grades Account from Third
                    Party Websites</strong>
            </p>
            <p>
                You will be able to connect your
                <strong>Sync Grades</strong> account to Google Classroom™.
            </p>
            <p>
                BY CONNECTING YOUR
                <strong>SYNC GRADES</strong> ACCOUNT TO YOUR THIRD PARTY ACCOUNT
                (<strong>Google Classroom™</strong>), Sync Grades will convert your
                grades into valid report card grades for
                <strong>Stars Classroom</strong>. This data is secure and protected
                and is <strong>not</strong> <strong>shared</strong> with any
                <strong>third-party website</strong>.
            </p>
            <p>
                FEATURE. You may disconnect your account from a third party account
                (<strong>Google Classroom™</strong>) at any time. Users can access the
                third party site to revoke and disconnect access.
            </p>
            <p><strong>E-mail Communications</strong></p>
            <p>
                From time to time,
                <strong>Sync Grades</strong> may contact you via email for the purpose
                of providing announcements, promotional offers, alerts, confirmations,
                surveys, and/or other general communication. In order to improve our
                Services, we may receive a notification when you open an email from
                <strong>Sync Grades</strong> or click on a link therein.
            </p>
            <p>
                If you would like to stop receiving marketing or promotional
                communications via email from
                <strong>Sync Grades</strong>, you may opt out of such communications
                by clicking the <strong>unsubscribe link </strong>located at the
                bottom of the email.
            </p>
            <p><strong>Changes to this Statement</strong></p>
            <p>
                <strong>Sync Grades</strong> reserves the right to
                <strong>enhance</strong> this <strong>Privacy Policy</strong> from
                time to time, in accordance with any new security provisions
                stipulated by the <strong>NYCDOE</strong> and the
                <strong>NYSED</strong>. We will notify you about significant changes
                in the way we treat personal information by sending a notice to the
                primary email address specified in your account, by placing a
                prominent notice on our site, and/or by updating any privacy
                information on this page. Your continued use of the Site and/or
                Services available through this Site after such modifications will
                constitute your: (a) acknowledgment of the
                <strong>modified Privacy Policy</strong>; and (b) agreement to abide
                and be bound by that Policy.
            </p>
            <p><strong>Contact Information</strong></p>
            <p>
                <strong>Sync Grades</strong> welcomes your questions or comments
                regarding this <strong>Statement of Privacy</strong>.
            </p>
            <p>
                If you believe that
                <strong>Sync Grades</strong> has not adhered to this Statement, please
                contact <strong>Sync Grades</strong> at:
            </p>
            <p><strong>Lilo Consulting, LLC</strong></p>
            <p>348 Rolling Hills Dr<br>East Stroudsburg, PA 18302</p>
            <p>Email Address:</p>
            <p><strong>sales@syncgrades.com</strong></p>
            <p>Effective as of May 18, 2020</p>
            <p />
        </div>
    </div>

    <!-- footer-section -->
    <LandingFooter />
</div>
</template>

<script>
import LandingHeader from '../components/landing/LandingHeader.vue';
import LandingFooter from '../components/landing/LandingFooter.vue';
import OtherHero from '../components/landing/OtherHero.vue';

export default {
    name: 'PrivacyPolicy',
    components: {
        LandingHeader,
        LandingFooter,
        OtherHero,
    },
};
</script>

<style scoped lang="scss">

.other-container {
  padding: 50px 10px;

  .content-box {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-size: 1.4em;
  }
}

</style>
