var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LandingHeader"),
      _c("OtherHero", { attrs: { heading: "Privacy Policy" } }),
      _vm._m(0),
      _c("LandingFooter"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "other-container" }, [
      _c("div", { staticClass: "content-box" }, [
        _c("p", [
          _vm._v(
            " Protecting your private information is our most important priority. This Statement of Privacy applies to"
          ),
          _c("a", { attrs: { href: "/" } }, [
            _vm._v(" https://www.syncgrades.com"),
          ]),
          _c("strong", [_vm._v(", Sync Grades,")]),
          _vm._v(" and "),
          _c("strong", [_vm._v("Lilo Consulting")]),
          _vm._v(", "),
          _c("strong", [_vm._v("LLC")]),
          _vm._v(
            ", which governs data collection and it’s usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to "
          ),
          _c("strong", [_vm._v("Lilo Consulting, LLC")]),
          _vm._v(" include "),
          _c("strong", [
            _c("a", { attrs: { href: "/", rel: "nofollow" } }, [
              _vm._v("http://syncgrades-webfront.s3.amazonaws.com/index.html"),
            ]),
          ]),
          _vm._v("and "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(". "),
        ]),
        _c("p", [
          _vm._v(" **The "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(
            " website and mobile application is a secure and protected Attendance – Grading system created by educators for educators; which strictly adheres to all of the security provisions stipulated within the "
          ),
          _c("strong", [
            _vm._v(
              "Family Educational Rights and Privacy Act (FERPA) (20 U.S.C. § 1232g; 34 CFR Part 99)"
            ),
          ]),
          _vm._v(" and the "),
          _c("strong", [_vm._v("NYSED")]),
          _vm._v(" – "),
          _c("strong", [
            _vm._v("Personally Identifiable Information Part 121 Terms"),
          ]),
          _vm._v(
            " (AMENDMENT TO THE REGULATIONS OF THE COMMISSIONER OF EDUCATION Pursuant to Education Law sections 2-d, 101, 207 and 305, a new Part 121). "
          ),
        ]),
        _c("p", [
          _vm._v(" By using the "),
          _c("strong", [_vm._v("Lilo Consulting, LLC., Sync Grades")]),
          _vm._v(
            " website, you consent to the data practices described in this statement. "
          ),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Collection of your Personal Information")]),
        ]),
        _c("p", [
          _vm._v(
            " We do not collect any personal information about you unless you voluntarily provide it to us. "
          ),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Sharing Information with Third Parties")]),
        ]),
        _c("p", [
          _vm._v(" **Sync Grades does not "),
          _c("strong", [_vm._v("sell")]),
          _vm._v(", "),
          _c("strong", [_vm._v("rent")]),
          _vm._v(" or "),
          _c("strong", [_vm._v("lease")]),
          _vm._v(" its customer lists to"),
          _c("strong", [_vm._v(" third parties in any shape, way, or form")]),
          _vm._v(". "),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(
            " will only share information within your secure school community as defined below: "
          ),
        ]),
        _c("ol", [
          _c("li", [
            _c("strong", [_vm._v("Teachers")]),
            _vm._v(
              " will only be allowed to view confidential data on their students assigned by the school administration "
            ),
          ]),
          _c("li", [
            _c("strong", [_vm._v("School Administrators")]),
            _vm._v(
              " will be able to view schoolwide data on all students within their school "
            ),
          ]),
          _c("li", [
            _c("strong", [_vm._v("Parents")]),
            _vm._v(
              " will only be able to view data on their daughter(s) or son(s) "
            ),
          ]),
          _c("li", [
            _c("strong", [_vm._v("Students")]),
            _vm._v(" will only be able to view their data "),
          ]),
          _c("li", [
            _c("strong", [
              _vm._v(
                "**NO Data, general or confidential information on students, parents, or staff is shared beyond the closed school community"
              ),
            ]),
          ]),
          _c("li", [
            _c("strong", [
              _vm._v(
                "The only exceptions to this rule is if the principal independently shares or formally requests in writing that information be shared with the district, superintendent, NYC Department of Education, or the NYSED"
              ),
            ]),
          ]),
          _c("li", [
            _c("strong", [
              _vm._v(
                "Under no circumstance will Sync Grades provide information to a third-party "
              ),
            ]),
          ]),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" provides teachers with the capacity to transmit "),
          _c("strong", [_vm._v("report card grades")]),
          _vm._v(" directly back to "),
          _c("strong", [_vm._v("Stars Classroom, ")]),
          _vm._v("via an Excel file. This is a feature integrated into "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" and is only accessible to "),
          _c("strong", [_vm._v("teachers")]),
          _vm._v(". "),
        ]),
        _c("p", [
          _vm._v(" Also, during the "),
          _c("strong", [_vm._v("COVID-19 Remote Learning Period")]),
          _vm._v(", "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(
            " allows school administrators to automate the attendance data collection process, also known as “"
          ),
          _c("strong", [_vm._v("Interactions.")]),
          _vm._v("” "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" allows schools to generate the required "),
          _c("strong", [_vm._v("Excel file")]),
          _vm._v(", needed to send to "),
          _c("strong", [_vm._v("Stars Classroom")]),
          _vm._v(". "),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Automatically Collected Information")]),
        ]),
        _c("p", [
          _vm._v(
            " Information about your computer hardware and software may be automatically collected by "
          ),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(
            ". This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation, maintenance and quality of service; and to provide general statistics regarding use of the "
          ),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" website. "),
        ]),
        _c("p", [_c("strong", [_vm._v("Use of Cookies")])]),
        _c("p", [
          _vm._v(" The "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" website may use “"),
          _c("strong", [_vm._v("cookies")]),
          _vm._v(
            "” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize "
          ),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" pages, or register with "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" site or services, a cookie helps "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(
            " to recall your specific information on subsequent visits. This simplifies the process of recording your personal information. When you return to the same "
          ),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(
            " website, the information you previously provided can be retrieved, so you can easily use the "
          ),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" features that you customized. "),
        ]),
        _c("p", [
          _vm._v(
            " You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to "
          ),
          _c("strong", [_vm._v("decline cookies")]),
          _vm._v(
            ", you may not be able to fully experience the interactive features of the "
          ),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" services or websites you visit. "),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Security of your Personal Information")]),
        ]),
        _c("p", [
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(
            " secures your personal information from unauthorized access, use, or disclosure. "
          ),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" uses the following methods for this purpose: "),
        ]),
        _c("p", [_vm._v("– "), _c("strong", [_vm._v("SSL Protocol")])]),
        _c("p", [
          _vm._v(
            " When personal information (such as the transfer of report card grades to the "
          ),
          _c("strong", [_vm._v("STARS Classroom")]),
          _vm._v(
            ") is transmitted to other websites, it is protected through the use of "
          ),
          _c("strong", [_vm._v("encryption")]),
          _vm._v(", such as the"),
          _c("strong", [_vm._v(" Secure Sockets Layer (SSL) protocol")]),
          _vm._v(". "),
        ]),
        _c("p", [
          _c("strong", [
            _vm._v(
              "**Sync Grades only transmits data back to Stars Classroom. There are no third-party websites that Sync Grades transmits data to."
            ),
          ]),
        ]),
        _c("p", [_c("strong", [_vm._v("Children Under Thirteen")])]),
        _c("p", [
          _vm._v(
            " If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website. If you are a parent and you have questions regarding our data collection practices, please contact us using the information provided at the end of this "
          ),
          _c("strong", [_vm._v("Statement of Privacy")]),
          _vm._v(". "),
        ]),
        _c("p", [
          _c("strong", [
            _vm._v(
              "Connecting and Disconnecting your Sync Grades Account from Third Party Websites"
            ),
          ]),
        ]),
        _c("p", [
          _vm._v(" You will be able to connect your "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" account to Google Classroom™. "),
        ]),
        _c("p", [
          _vm._v(" BY CONNECTING YOUR "),
          _c("strong", [_vm._v("SYNC GRADES")]),
          _vm._v(" ACCOUNT TO YOUR THIRD PARTY ACCOUNT ("),
          _c("strong", [_vm._v("Google Classroom™")]),
          _vm._v(
            "), Sync Grades will convert your grades into valid report card grades for "
          ),
          _c("strong", [_vm._v("Stars Classroom")]),
          _vm._v(". This data is secure and protected and is "),
          _c("strong", [_vm._v("not")]),
          _vm._v(" "),
          _c("strong", [_vm._v("shared")]),
          _vm._v(" with any "),
          _c("strong", [_vm._v("third-party website")]),
          _vm._v(". "),
        ]),
        _c("p", [
          _vm._v(
            " FEATURE. You may disconnect your account from a third party account ("
          ),
          _c("strong", [_vm._v("Google Classroom™")]),
          _vm._v(
            ") at any time. Users can access the third party site to revoke and disconnect access. "
          ),
        ]),
        _c("p", [_c("strong", [_vm._v("E-mail Communications")])]),
        _c("p", [
          _vm._v(" From time to time, "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(
            " may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from "
          ),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" or click on a link therein. "),
        ]),
        _c("p", [
          _vm._v(
            " If you would like to stop receiving marketing or promotional communications via email from "
          ),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(", you may opt out of such communications by clicking the "),
          _c("strong", [_vm._v("unsubscribe link ")]),
          _vm._v("located at the bottom of the email. "),
        ]),
        _c("p", [_c("strong", [_vm._v("Changes to this Statement")])]),
        _c("p", [
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" reserves the right to "),
          _c("strong", [_vm._v("enhance")]),
          _vm._v(" this "),
          _c("strong", [_vm._v("Privacy Policy")]),
          _vm._v(
            " from time to time, in accordance with any new security provisions stipulated by the "
          ),
          _c("strong", [_vm._v("NYCDOE")]),
          _vm._v(" and the "),
          _c("strong", [_vm._v("NYSED")]),
          _vm._v(
            ". We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the "
          ),
          _c("strong", [_vm._v("modified Privacy Policy")]),
          _vm._v("; and (b) agreement to abide and be bound by that Policy. "),
        ]),
        _c("p", [_c("strong", [_vm._v("Contact Information")])]),
        _c("p", [
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" welcomes your questions or comments regarding this "),
          _c("strong", [_vm._v("Statement of Privacy")]),
          _vm._v(". "),
        ]),
        _c("p", [
          _vm._v(" If you believe that "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" has not adhered to this Statement, please contact "),
          _c("strong", [_vm._v("Sync Grades")]),
          _vm._v(" at: "),
        ]),
        _c("p", [_c("strong", [_vm._v("Lilo Consulting, LLC")])]),
        _c("p", [
          _vm._v("348 Rolling Hills Dr"),
          _c("br"),
          _vm._v("East Stroudsburg, PA 18302"),
        ]),
        _c("p", [_vm._v("Email Address:")]),
        _c("p", [_c("strong", [_vm._v("sales@syncgrades.com")])]),
        _c("p", [_vm._v("Effective as of May 18, 2020")]),
        _c("p"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }